import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}></MDXTag>

export const _frontmatter = {"title":"How does feedback from others help us get better at writing?","key":"how-does-feedback-from-others-help-us-get-better-at-writing","parentKey":"writing-is-social","templateKey":"content-pages"};

  